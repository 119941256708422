import React from "react";
import PageWrapper from "../components/PageWrapper";
import { Title, Section, Text, Button } from "../components/Core";
import { Col, Container, Row } from "react-bootstrap";
import { navigate } from "gatsby";
import Seo from "../components/Seo";
import heroImg from "./../assets/image/jpeg/hero-commercial-wayleaves.jpg";
import planning1 from "./../assets/image/jpeg/what-is-full-fibre-broadband.jpg";

import icon1 from "./../assets/image/svg/icon-wayleaves-business-1.svg";
import icon2 from "./../assets/image/svg/icon-wayleaves-business-2.svg";
import icon3 from "./../assets/image/svg/icon-wayleaves-business-3.svg";
import icon4 from "./../assets/image/svg/icon-wayleaves-business-4.svg";
import connectMoreDevicesIcon from "./../assets/image/svg/connect-more-devices-icon.svg";

import businessBackground from "./../assets/image/jpeg/wayleaves-business-background.jpg";

import wayleaveAbout from "./../assets/image/png/wayleaves-business-about.png";
import commercialBlock from "./../assets/image/jpeg/commercial-wayleaves--block.jpg";
import styled from "styled-components";

import Link from "gatsby-link";

const CommercialWayleaves = () => {

	const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 35%;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 35%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 35%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

	const NarrowWrapper = styled.div`
        width: 65%;
        padding: 0 15px 0 15px;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
    `;

	const PinkTitle = styled(Title)`
		color: #db118b;
		margin-top: 100px;
		text-align: left;
		padding-left: 15px;
		@media (max-width: 991px) {
			margin-top: 20px;
		}
	`;

	const SmallParagraph = styled(Text)`
		font-size: 18px;
		line-height: 30px;
	`;

	const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        padding: 100px 0 0 0;
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
            padding-bottom: 100px;
        }
    `;

	const GradientBox = styled(Section)`
		margin: 100px 0 80px 0;
		position: relative;
		background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
		padding: 0;
		border-radius: 50px;
		padding: 100px 50px;
		h2, p {
			color: #fff;
		}
		p {
			line-height: 1.2;
			margin-top: 16px;
		}
		img {
			height: 100px;
		}
	`;

	const OutlineDiv = styled.div`
		padding: 20px;
		border: 1px solid #fff;
		border-radius: 50px;
		p { 
			margin: 0;
		}
		width: 500px;
		max-width: 100%;
		margin: auto;
	`;

	const PinkBackground = styled.div`
		background-image: url(${businessBackground});
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 100px;
	`;

	return (
		<>
			<Seo page="commercial-wayleaves"/>
			<div className="hero-wayleaves">
                <img src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>
					Business<br />
					wayleaves
				</AbsoluteTitle>
            </div>
			<PageWrapper footerDark>
				<Row className="justify-content-center text-center dark-bg pt-5">
					<Col lg="5">
						<img src={wayleaveAbout} className="w-100 first-img"/>
					</Col>
					<Col lg={1}></Col>
					<Col lg="6">
						<PinkTitle>What is a wayleave?</PinkTitle>
						<NarrowWrapper>
							<Text>
								A wayleave is an agreement between
								the freeholder and a supplier, in this
								case Netomnia. This gives the supplier
								the right to access the property and
								carry out pre-agreed works to install
								the supplier's infrastructure. The
								Wayleave also allows the supplier to
								maintain the infrastructure under prior
								notice to the freeholder.
							</Text>
						</NarrowWrapper>
					</Col>
				</Row>
				<PinkBackground className="background-light-pink mb-0 pb-0">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <Title variant="news">What is full fibre<br />broadband?</Title>
                                <SmallParagraph>
									Full fibre broadband is the latest in broadband
									technology. It is made of entirely fibre optic
									cables which can allow data to travel at the
									speed of light. This means that your broadband
									speed will greatly increase.
                                </SmallParagraph>
                                <SmallParagraph>
									Current networks are made using copper wires
									which vastly hinders performance.
                                </SmallParagraph>
                            </Col>
                            <Col lg="1"></Col>
                            <Col lg="6" className="centered">
                                <img src={planning1} className="w-100 border-radius-10"/>
                            </Col>
                        </Row>
						<GradientBox className="text-center">
							<Title>
								What could you be<br />
								missing out on?
							</Title>
							<Text>
								By staying on slow, aging copper<br />
								wires, you could miss out on:
							</Text>
							<Row>
								<Col lg={3} className="pl-5 pr-5 mt-5">
									<img src={icon1} />
									<Text>
										Greater<br />
										reliability
									</Text>
								</Col>
								<Col lg={3} className="pl-5 pr-5 mt-5">
									<img src={icon2} />
									<Text>
										Cloud storage <br />
										capability
									</Text>
								</Col>
								<Col lg={3} className="pl-5 pr-5 mt-5">
									<img src={icon3} />
									<Text>
										Connect more<br />
										devices
									</Text>
								</Col>
								<Col lg={3} className="pl-5 pr-5 mt-5">
									<img src={icon4} />
									<Text>
										Enhanced<br />
										security
									</Text>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<OutlineDiv className="mt-5">
										<Text>
											Full fibre is perfect to upgrade your<br/>
											business's productivity and security.
										</Text>
									</OutlineDiv>
								</Col>
							</Row>
						</GradientBox>
						<Row>
                            <Col lg="6">
                                <Title variant="news" className="mt-5">
									How to access full<br />
									fibre broadband
								</Title>
								<SmallParagraph>
									We also need an up-to-date asbestos register for buildings built pre 2000 included.
								</SmallParagraph>
                                <SmallParagraph>
									As the network provider, we need permission
									form the freeholder before installing any of our
									equipment in their premises.
                                </SmallParagraph>
								<SmallParagraph>
									Please ensure that you have spoken to your
									building owner and have them sign a wayleave
									agreement from us. Get in touch with the team
									and see how we can help.
								</SmallParagraph>
								<a href="mailto:wayleaves@netomnia.com">
									<Button
										style={{
											background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
											border: 'none',
											width: 'auto'
										}}
										className="btn-red hvr-bounce-to-left mt-5"
									>
										Get in touch
									</Button>
								</a>
                            </Col>
                            <Col lg="6">
								<Title variant="news" className="mt-5">
									Why do we need
									freeholder permission?
								</Title>
                                <SmallParagraph>
									It’s all down to a question of rights of possession due
									to equipment installation. Your landlord will want to
									make sure that the installation (and, if necessary,
									removal at the end of the tenancy) isn’t going to cost
									them any money.
                                </SmallParagraph>
								<SmallParagraph>
									They also must ensure that any installation conforms
									with health & safety legislation and doesn't
									compromise the building's interior or exterior.
								</SmallParagraph>
                            </Col>
                        </Row>
                    </Container>
					<Row>
						<Col lg={12} className="mt-5 pt-5">
							<img src={commercialBlock} width={'100%'}/>
						</Col>
					</Row>
                </PinkBackground>
				<GradientSection>
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <Title variant="news">
									Next steps to get<br />
									full fibre broadband
								</Title>
                                <Text className="w-80 centered">
									If you want full fibre broadband to your office,
									please open the conversation with your building
									owner about accessing it.
                                </Text>
								<Text className="w-80 centered">
									Alternatively, reach out to our friendly expert team
									with their contact details. Email us at <a href="mailto:wayleaves@netomnia.com">wayleaves@netomnia.com</a> and we will be more
									than happy to reach out to them directly. 
								</Text>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
			</PageWrapper>
		</>
	);
}
export default CommercialWayleaves
